import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Grid2,Paper, Tabs, Tab, Button, Divider, Link } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ENV from "../env";



const TabPanel = (props) => {
const { children, value, index, ...other } = props;
return (

<div role="tabpanel" hidden={value !==index} {...other}>
    {value === index &&
    <Box p={3}>{children}</Box>}
</div>
);
};

const handleDownload = async (url, fileName = 'documento') => {
  try {
     const baseUrl = ENV.BACKEND_URL_BASE || 'http://localhost:9091';
      
     const response = await axios.post(
        `${baseUrl}/services/underwriting@1.0/download`,
        { data: { back_url: url } }
     );

      const base64Data = response.data.data;
      const byteCharacters = atob(base64Data);
      const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
      const byteArray = new Uint8Array(byteNumbers);

      let mimeType = 'application/pdf';
      if (url.endsWith('.jpg') || url.endsWith('.jpeg')) mimeType = 'image/jpeg';
      else if (url.endsWith('.png')) mimeType = 'image/png';

      const blob = new Blob([byteArray], { type: mimeType });

      const fileURL = window.URL.createObjectURL(blob);
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `${fileName}${mimeType.includes('pdf') ? '.pdf' : ''}`);

      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);

  } catch (error) {
      console.error("Error al descargar el archivo:", error);
  }
};



const TransactionDetail = () => {
const { customer_uuid } = useParams();
const navigate = useNavigate();
const [tabIndex, setTabIndex] = useState(0);
const [leadDetails, setLeadDetails] = useState(null);
const [documentsDetails, setDocumentsDetails] = useState(null);
const [accountsDetails, setAccountsDetails] = useState(null);
const [agreementsDetails, setAgreementsDetails] = useState(null);
const [creditDetails, setCreditDetails] = useState(null);
const [stakeholdersDetails, setStakeholdersDetails] = useState(null);
const [loading, setLoading] = useState(true);

const handleTabChange = (event, newIndex) => {
setTabIndex(newIndex);
};


useEffect(() => {
  const fetchData = async () => {
      try {
        const baseUrl = ENV.BACKEND_URL_BASE || 'http://localhost:9091';
        const response = await fetch(
            `${baseUrl}/services/underwriting@1.0/details`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                  data: {
                      filter: {
                          customer_uuid,
                      },
                  },
              }),
          });
          const data = await response.json();
          if (data.response === "OK") {
              setLeadDetails(data.data.lead);
              setAccountsDetails(data.data.accounts);
              setDocumentsDetails(data.data.lead.kyc.documents);
              setAgreementsDetails(data.data.agreements);
              setCreditDetails(data.data.credit);
              setStakeholdersDetails(data.data.stakeholders);
          } else {
              console.error("Error in response:", data);
          }
      } catch (error) {
          console.error("Error fetching data:", error);
      } finally {
          setLoading(false);
      }
  };

fetchData();
}, [customer_uuid]);

if (loading) return
<Typography>Loading...</Typography>;
if (!leadDetails) return
<Typography>Error loading data.</Typography>;

return (
<Box p={4}>
    {/* Botón para regresar al Grid */}

    <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button
        variant="contained"
        onClick={() => navigate('/')}
        startIcon={
            <HomeIcon />}
        sx={{
        backgroundColor: '#f5f5f5',
        color: 'gray',
        '&:hover': {
        backgroundColor: '#e0e0e0',
        },
        }}
        >
        </Button>
    </Box>

    {/* Encabezado de la Empresa */}
    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h5">{leadDetails.general.bname || 'Nombre de Empresa'}</Typography>
        <Typography variant="subtitle1" color="green">
            Estatus: {leadDetails.status === 'enabled' ? 'Activo' : 'Inactivo'}
        </Typography>
    </Box>
    <Grid container spacing={2}>
        {/* Detalle de la Empresa */}

        <Grid item xs={12} md={4}>
            <Paper variant="outlined" sx={{ padding: 2 }}>
                <Typography variant="h6">DETALLE DE LA EMPRESA</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Typography variant="body2" color="textSecondary">Nombre comercial:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2">{leadDetails.general.gname}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" color="textSecondary">RFC:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2">{leadDetails.general.taxid}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" color="textSecondary">Giro comercial:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2">{leadDetails.general.activity.value}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" color="textSecondary">Fecha de constitución:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2">{leadDetails.general.sdate}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" color="textSecondary">Régimen Fiscal:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2">{leadDetails.general.tax_regimen.value}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" color="textSecondary">Dirección:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2">
                            {`${leadDetails.address.invoicing.street}, ${leadDetails.address.invoicing.municipality},
                            ${leadDetails.address.invoicing.state.value}, ${leadDetails.address.invoicing.zip}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" color="textSecondary">Sitio web:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2">{leadDetails.kyc.identity.site || 'N/A'}</Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>

        {/* Opciones de pestañas */}
        <Grid item xs={12} md={8}>
            <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Detalle Tabs">
                <Tab label="Documentación" />
                <Tab label="Cuentas" />
                <Tab label="Agreements" />
                <Tab label="Crédito" />
                <Tab label="Stakeholders" />
            </Tabs>
            {/*Documentos*/}
            <TabPanel value={tabIndex} index={0}>
                <Typography variant="h6" gutterBottom>Documentos</Typography>
                <Grid container spacing={2}>
                    {documentsDetails && (
                    <>
                        {["ac", "cfiel", "csf", "cif", "birpc"].map((docKey) => (
                        <Grid item xs={12} md={6} key={docKey}>
                            <Paper variant="outlined" sx={{ padding: 2 }}>
                                <Typography variant="body2" color="textSecondary">Nombre:</Typography>
                                <Typography variant="body1">{docKey.toUpperCase()}</Typography>
                                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>URL:</Typography>
                                <Link onClick={()=> handleDownload(documentsDetails[docKey].url)}
                                style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                >
                                Descargar {docKey.toUpperCase()}
                                </Link>
                                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>Fecha:</Typography>
                                <Typography variant="body1">
                                    {new Date(documentsDetails[docKey].cdate).toLocaleDateString()}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>Life Cycle:
                                </Typography>
                                <Typography variant="body1">{documentsDetails[docKey].life_cycle}</Typography>
                            </Paper>
                        </Grid>
                        ))}
                    </>
                    )}
                </Grid>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    {/* Comprobante de Domicilio */}
                    <Grid item xs={12}>
                        <Paper variant="outlined" sx={{ padding: 3 }}>
                            <Typography variant="subtitle1" gutterBottom>Comprobante de Domicilio</Typography>
                            {documentsDetails.address_proof ? (
                            <>
                                <Typography variant="body2" color="textSecondary">Tipo:</Typography>
                                <Typography variant="body1">{documentsDetails.address_proof.metadata.data.type}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">A nombre de:</Typography>
                                <Typography variant="body1">{documentsDetails.address_proof.metadata.data.owner_name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">Dirección:</Typography>
                                <Typography variant="body1">
                                    {`${documentsDetails.address_proof.metadata.data.address.street},
                                    ${documentsDetails.address_proof.metadata.data.address.locality},
                                    ${documentsDetails.address_proof.metadata.data.address.city},
                                    ${documentsDetails.address_proof.metadata.data.address.zip}`}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">URL:</Typography>
                                <Link onClick={()=> handleDownload(documentsDetails.address_proof.url)}
                                style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                >
                                Descargar Comprobante
                                </Link>
                            </>
                            ) : (
                            <Typography variant="body2">No disponible</Typography>
                            )}
                        </Paper>
                    </Grid>

                    {/* Estados de Cuenta */}
                    <Grid item xs={12}>
                        <Paper variant="outlined" sx={{ padding: 3 }}>
                            <Typography variant="subtitle1" gutterBottom>Estados de Cuenta</Typography>
                            {documentsDetails.account_statements ? (
                            Object.values(documentsDetails.account_statements).map((statement, index) => (
                            <div key={index}>
                                <Typography variant="body2" color="textSecondary">Life Cycle:</Typography>
                                <Typography variant="body1">{statement.life_cycle}</Typography>
                                <Typography variant="body2" color="textSecondary">URL:</Typography>
                                <Link onClick={()=> handleDownload(statement.url)}
                                style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                >
                                Descargar Estado de Cuenta
                                </Link>
                                <Divider sx={{ my: 2 }} />
                            </div>
                            ))
                            ) : (
                            <Typography variant="body2">No disponible</Typography>
                            )}
                        </Paper>
                    </Grid>

                    {/* Facemap */}
                    <Grid item xs={12}>
                        <Paper variant="outlined" sx={{ padding: 3 }}>
                            <Typography variant="subtitle1" gutterBottom>Facemap</Typography>
                            {documentsDetails.facemap ? (
                            Object.values(documentsDetails.facemap).map((face, index) => (
                            <div key={index}>
                                <Typography variant="body2" color="textSecondary">Fecha:</Typography>
                                <Typography variant="body1">{new Date(face.cdate).toLocaleDateString()}</Typography>
                                <Typography variant="body2" color="textSecondary">URL:</Typography>
                                <Link onClick={()=> handleDownload(face.url)}
                                style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                >
                                Descargar Facemap
                                </Link>
                                <Divider sx={{ my: 2 }} />
                            </div>
                            ))
                            ) : (
                            <Typography variant="body2">No disponible</Typography>
                            )}
                        </Paper>
                    </Grid>

                    {/* Identificación */}
                    <Grid item xs={12}>
                        <Paper variant="outlined" sx={{ padding: 3 }}>
                            <Typography variant="subtitle1" gutterBottom>Identificación</Typography>
                            {documentsDetails.id ? (
                            <>
                                <Typography variant="body2" color="textSecondary">Frente:</Typography>
                                <Link onClick={()=> handleDownload(documentsDetails.id.front.url)}
                                style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                >
                                Descargar Frente
                                </Link>
                                <Typography variant="body2" color="textSecondary">Reverso:</Typography>
                                <Link onClick={()=> handleDownload(documentsDetails.id.back.url)}
                                style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                >
                                Descargar Reverso
                                </Link>
                            </>
                            ) : (
                            <Typography variant="body2">No disponible</Typography>
                            )}
                        </Paper>
                    </Grid>

                    {/* Selfie */}
                    <Grid item xs={12}>
                        <Paper variant="outlined" sx={{ padding: 3 }}>
                            <Typography variant="subtitle1" gutterBottom>Selfie</Typography>
                            {documentsDetails.selfie ? (
                            <Link onClick={()=> handleDownload(documentsDetails.selfie.face.url)}
                            style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                            >
                            Descargar Selfie
                            </Link>
                            ) : (
                            <Typography variant="body2">No disponible</Typography>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </TabPanel>

            {/* Accounts*/ }
            <TabPanel value={tabIndex} index={1}>
                <Typography variant="h6" gutterBottom>Cuentas</Typography>
                <Grid container spacing={2}>
                    {accountsDetails && (

                    <Grid item xs={12}>
                        <Paper variant="outlined" sx={{ padding: 3, maxWidth: 600, margin: '0 auto' }}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">Nombre:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">
                                        {`${accountsDetails.general.gname} ${accountsDetails.general.lname}
                                        ${accountsDetails.general.slname}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">CURP:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{accountsDetails.general.populationid}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">Email:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{accountsDetails.general.email}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">RFC:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{accountsDetails.general.taxid}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">Teléfono:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{accountsDetails.general.phone}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">Validación nombre registrado:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">
                                        {accountsDetails.validation.email.details.registrar_name}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">Facebook:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">
                                        {accountsDetails.validation.email.details.facebook.registered ? 'Registrado' :
                                        'No registrado'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">Instagram:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">
                                        {accountsDetails.validation.email.details.instagram.registered ? 'Registrado' :
                                        'No registrado'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">Linkedin:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">
                                        {accountsDetails.validation.email.details.linkedin.registered ? 'Registrado' :
                                        'No registrado'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2" color="textSecondary" gutterBottom>Referencias:
                                    </Typography>
                                    <Paper variant="outlined" sx={{ padding: 2 }}>
                                        <Grid container spacing={1}>
                                            {accountsDetails && accountsDetails.references ? (
                                            accountsDetails.references.map((reference, index) => (

                                            <Grid container key={index} spacing={1}>
                                                <Grid item xs={6}>
                                                    <Typography variant="body2" color="textSecondary">Nombre:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="body1">
                                                        {`${reference.gname} ${reference.lname} ${reference.slname ||
                                                        ''}`}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="body2" color="textSecondary">Relación:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="body1">{reference.relationship.value}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="body2" color="textSecondary">Teléfono:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="body1">{reference.phone}</Typography>
                                                </Grid>
                                            </Grid>
                                            ))
                                            ) : (
                                            <Typography variant="body2" color="textSecondary">
                                                No hay referencias disponibles.
                                            </Typography>
                                            )}
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    )}
                </Grid>
            </TabPanel>


            {/* Agregar TabPanel para Agreements */}
            <TabPanel value={tabIndex} index={2}>
                <Typography variant="h6" gutterBottom>Agreements</Typography>
                <Grid container spacing={2}>
                    {agreementsDetails && (

                    <Grid item xs={12}>
                        <Paper variant="outlined" sx={{ padding: 3, maxWidth: 600, margin: '0 auto' }}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">Tipo de Producto:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{agreementsDetails.template.product_type}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">Tipo de Template:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{agreementsDetails.template.type_template}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">Link:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Link href={agreementsDetails.template.url_templates.url} target="_blank"
                                        rel="noopener">
                                    {agreementsDetails.template.url_templates.url}
                                    </Link>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">Título:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{agreementsDetails.template.url_templates.title}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">Fecha de Firma:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{new
                                        Date(agreementsDetails.metadata.sign_date).toLocaleDateString()}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">Estatus:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1" color={agreementsDetails.status==='enabled' ? 'green'
                                        : 'red' }>
                                        {agreementsDetails.status === 'enabled' ? 'Activo' : 'Inactivo'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    )}
                </Grid>
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
                <Typography variant="h6" gutterBottom>Créditos</Typography>
                <Grid container spacing={2}>
                    {creditDetails && (

                    <Grid item xs={12}>
                        <Paper variant="outlined" sx={{ padding: 3, maxWidth: 600, margin: '0 auto' }}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">Estatus:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1" color={creditDetails.status==='enabled' ? 'green'
                                        : 'red' }>
                                        {creditDetails.status === 'enabled' ? 'Activo' : 'Inactivo'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">Lifecycle:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{creditDetails.lifecycle}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">RFC:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{creditDetails.metadata.data[0].rfc}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">Nombre Legal:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{creditDetails.metadata.data[0].legalName}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">Total:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">${creditDetails.metadata.data[0].total.toFixed(2)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2" color="textSecondary" gutterBottom>Transacciones:
                                    </Typography>
                                    <Paper variant="outlined" sx={{ padding: 2 }}>
                                        <Grid container spacing={1}>
                                            {creditDetails.metadata.data[0].transactions.map((transaction, index) => (

                                            <Grid container key={index} spacing={1}>
                                                <Grid2 item xs={6}>
                                                    <Typography variant="body2" color="textSecondary">Fecha:
                                                    </Typography>
                                                </Grid2>
                                                <Grid2 item xs={6}>
                                                    <Typography variant="body1">{transaction.date}</Typography>
                                                </Grid2>
                                                <Grid2 item xs={6}>
                                                    <Typography variant="body2" color="textSecondary">Total:
                                                    </Typography>
                                                </Grid2>
                                                <Grid2 item xs={6}>
                                                    <Typography variant="body1">${transaction.total.toFixed(2)}
                                                    </Typography>
                                                </Grid2>
                                            </Grid>
                                            ))}
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    )}
                </Grid>
            </TabPanel>
            <TabPanel value={tabIndex} index={4}>
                <Typography variant="h6" gutterBottom>Stakeholders</Typography>
                <Grid container spacing={2}>
                    {stakeholdersDetails && (

                    <Grid item xs={12}>
                        <Paper variant="outlined" sx={{ padding: 3, maxWidth: 600, margin: '0 auto' }}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">Nombre:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">
                                        {`${stakeholdersDetails.general.gname} ${stakeholdersDetails.general.lname}
                                        ${stakeholdersDetails.general.slname || ''}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">Fecha de Nacimiento:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">
                                        {new Date(stakeholdersDetails.general.bdate).toISOString().split('T')[0]}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">País:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{stakeholdersDetails.general.country.value}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">Estado:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{stakeholdersDetails.general.state.value}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">RFC:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{stakeholdersDetails.general.taxid}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">Sexo:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{stakeholdersDetails.general.gender.value}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">CURP:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{stakeholdersDetails.general.populationid}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">Actividad:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{stakeholdersDetails.general.activity.value}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">Correo Electrónico:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{stakeholdersDetails.general.email}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">Teléfono:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{stakeholdersDetails.general.phone}</Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    )}
                </Grid>
            </TabPanel>
        </Grid>
    </Grid>
</Box>
);
};

export default TransactionDetail;