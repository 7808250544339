import React from 'react';
import { Typography, Box } from '@mui/material';

const Footer = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50px',
        backgroundColor: '#f8f8f8',
        borderTop: '1px solid #e7e7e7',
        position: 'fixed',
        bottom: 0,
        width: '100%',
      }}
    >
      <Typography variant="body2" color="textSecondary">
        © 2024 Delta Back Office. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
