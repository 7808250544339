import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import LeadAccountsGrid from './components/LeadAccountsGrid';
import TransactionDetail from './components/TransactionDetail';
import { signOut } from 'firebase/auth';
import { auth } from './firebase';
import Header from './components/Header'; 
import Footer from './components/Footer'; 
import ENV from "./env";

function App() {
  const [user, setUser] = useState(null);
  const [data, setData] = useState({ leads: [], accounts: [] });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  

  const handleLogin = (user) => {
    setUser(user);
    fetchGridData();
  };

  const handleLogout = async () => {
    await signOut(auth);
    setUser(null);
  };

  const fetchGridData = async () => {
    setLoading(true);
    setError(null);

    try {

      const baseUrl = ENV.BACKEND_URL_BASE || 'http://localhost:9091';

      const response = await fetch(`${baseUrl}/services/underwriting@1.0/list`, {
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error('Error fetching data');
      }

      const result = await response.json();
      setData({
        leads: result.data.leads,
        accounts: result.data.accounts,
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Router>
      <div>
        {user ? (
          <>
            <Header onLogout={handleLogout} />
            <div style={{ padding: '20px', minHeight: '80vh' }}>
              <Routes>
                <Route
                  path="/"
                  element={
                    loading ? (
                      <p>Loading...</p>
                    ) : error ? (
                      <p>Error: {error}</p>
                    ) : (
                      <LeadAccountsGrid leads={data.leads} accounts={data.accounts} />
                    )
                  }
                />
                <Route path="/leads/:customer_uuid" element={<TransactionDetail />} />
              </Routes>
            </div>
            <Footer />
          </>
        ) : (
          <Login onLogin={handleLogin} />
        )}
      </div>
    </Router>
  );
}

export default App;
