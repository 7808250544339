import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Avatar, Typography, TextField, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';

const leadColumns = (navigate) => [
  {
    field: 'company',
    headerName: 'Nombre de la empresa',
    width: 200,
    renderCell: (params) => (
      <Box display="flex" alignItems="center">
        <Avatar sx={{ bgcolor: '#e0e0e0', mr: 1 }}>{params.value[0]}</Avatar>
        <Box>
          <Typography variant="body1">{params.value}</Typography>
        </Box>
      </Box>
    ),
  },
  {
    field: 'full_name',
    headerName: 'Contacto',
    width: 300,
    renderCell: (params) => (
      <Box display="flex" alignItems="center">
        <Avatar sx={{ bgcolor: '#e0e0e0', mr: 1 }}>{params.value[0]}</Avatar>
        <Box>
          <Typography variant="body1">{params.value}</Typography>
          <Typography variant="body2" color="textSecondary">
            {params.row.type ? params.row.type.charAt(0).toUpperCase() + params.row.type.slice(1) : ''}
          </Typography>
        </Box>
      </Box>
    ),
  },
  {
    field: 'email',
    headerName: 'Correo electrónico',
    width: 240,
    renderCell: (params) => (
      <Box display="flex" alignItems="flex-start">
        <Typography variant="body1">{params.value}</Typography>
      </Box>
    ),
  },
  {
    field: 'amount',
    headerName: 'Monto de línea',
    width: 140,
    align: 'right',
    renderCell: (params) => (
      <Box display="flex" alignItems="flex-start" justifyContent="flex-end">
        <Typography variant="body1">{params.value}</Typography>
      </Box>
    ),
  },
  {
    field: 'start_revision',
    headerName: 'Fecha de inicio de producto',
    width: 150,
    renderCell: (params) => (
      <Box display="flex" alignItems="flex-start">
        <Typography variant="body1">{params.value}</Typography>
      </Box>
    ),
  },
  {
    field: 'status',
    headerName: 'Estatus',
    width: 80,
    renderCell: (params) => (
      <Typography variant="body1" color={params.value === 'enabled' ? 'green' : 'red'}>
        {params.value === 'enabled' ? 'Activo' : 'Inactivo'}
      </Typography>
    ),
  },
  {
    field: 'actions',
    headerName: 'Detalle',
    width: 90,
    renderCell: (params) => {
      const handleDetailsClick = () => {
        console.log("customer_uuid pantalla grid list:",params.row.customer_uuid)
        navigate(`/leads/${params.row.customer_uuid}`);
      };

      return (
        <Button
          variant="contained"
          onClick={handleDetailsClick}
          startIcon={<SearchIcon sx={{ color: 'gray' }} />}
          sx={{ backgroundColor: '#e0e0e0', color: 'gray', '&:hover': { backgroundColor: '#d0d0d0' } }}
        />
      );
    },
  },
];

const LeadAccountsGrid = ({ leads }) => {

  const navigate = useNavigate();
  
  const [companyFilter, setCompanyFilter] = useState('');
  const [representativeFilter, setRepresentativeFilter] = useState('');

  const filteredLeads = leads.filter((lead) => {
    const matchesCompany = lead.company.toLowerCase().includes(companyFilter.toLowerCase());
    const matchesRepresentative = lead.full_name.toLowerCase().includes(representativeFilter.toLowerCase());
    return matchesCompany && matchesRepresentative;
  });

  return (
    <Box sx={{ maxWidth: '1200px', mx: 'auto', marginBottom: 4 }}>
      <h2>Leads</h2>

      {/* Filtros de búsqueda */}
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item xs={6} sm={4}>
          <TextField
            label="Buscar por empresa"
            variant="outlined"
            fullWidth
            value={companyFilter}
            onChange={(e) => setCompanyFilter(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            label="Buscar por  contacto"
            variant="outlined"
            fullWidth
            value={representativeFilter}
            onChange={(e) => setRepresentativeFilter(e.target.value)}
          />
        </Grid>
      </Grid>

      <DataGrid
        rows={filteredLeads.map((lead, index) => ({
          id: index,
          ...lead,
          amount: lead.amount ? `$${lead.amount.toLocaleString()}` : '',
        }))}
        columns={leadColumns(navigate)}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 25, 50]}
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#f5f5f5',
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-cell': {
            padding: '8px',
          },
          '& .MuiDataGrid-row': {
            '&:nth-of-type(even)': { backgroundColor: '#f9f9f9' },
            '&:hover': { backgroundColor: '#e3f2fd' },
          },
          '& .MuiDataGrid-footerContainer': {
            justifyContent: 'center',
            '& .MuiButtonBase-root': {
              borderRadius: '50%',
              margin: '0 5px',
            },
          },
        }}
      />
    </Box>
  );
};

export default LeadAccountsGrid;
